import { PlacesType, Tooltip } from 'react-tooltip';
import styled from 'styled-components';
import FormControl from 'react-bootstrap/lib/FormControl';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';

export type ValidationState =
    | 'error'
    | 'warning'
    | 'success'
    | null
    | undefined;

export interface Validation {
    state?: ValidationState;
    message?: string | null;
    toolTip?: { id: string; place: PlacesType };
}

interface FormValidationDisplayProps {
    validation: Validation | undefined;
    style?: Object;
    messageStyle?: Object;
    hideIcon?: boolean;
}

const FormValidationDisplay = ({
    validation,
    style,
    messageStyle,
    hideIcon,
}: FormValidationDisplayProps) => {
    const getValidationStateIcon = (validationState: ValidationState) => {
        if (hideIcon) return '';
        switch (validationState) {
            case 'success':
                return 'fa fa-check-circle';
            case 'error':
                return 'fa fa-times';
            default:
                return '';
        }
    };
    const getValidationMessage = (
        validationState: ValidationState,
        validationMessage: Validation['message']
    ) => {
        switch (validationState) {
            case 'error':
                return validationMessage || 'Invalid';
            case 'warning':
                return validationMessage || 'Warning';
            default:
                return '';
        }
    };
    const validationMessage = getValidationMessage(
        validation?.state,
        validation?.message
    );

    return (
        <>
            <FormControl.Feedback style={style}>
                <i className={getValidationStateIcon(validation?.state)} />
            </FormControl.Feedback>
            {validation?.toolTip ? (
                <StyledTooltip
                    id={validation?.toolTip.id}
                    variant={validation?.state || 'info'}
                    place={validation.toolTip.place}
                    disableStyleInjection='core'
                >
                    {validationMessage}
                </StyledTooltip>
            ) : (
                <HelpBlock style={messageStyle}>{validationMessage}</HelpBlock>
            )}
        </>
    );
};
const StyledTooltip = styled(Tooltip)`
    z-index: 99999;
    visibility: visible;
`;

export default FormValidationDisplay;
